/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Col, Modal, Row, Tooltip } from 'antd';
import MainFooter from '../../../components/MainFooter';
import { sendDataPDFportfolio } from '../../../data/store/portfolio/portfolioActions';
import { PortfolioTableOptimal } from '../../../components/PortfolioTable/PortfolioTableOptimal';
import Container from '../../../components/Container';
import { twoDecimalWithSpaces } from '../../../helpers/formatFractional';

const StepSuggestedPortfolio = ({
  userId,
  portfolioId,
  pdfSource,
  setPDFSource,
  optAlert,
  setOptAlert,
  setOptStart,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const history = useHistory();

  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [modalFinishVisible, setModalFinishVisible] = useState(false);
  const [tableData, setTableData] = useState();
  const [tableColumns, setTableColumns] = useState();

  const precandidate = useSelector((state) => state.portfolioReducer?.precandidate);
  const cash_out_sek = useSelector((state) => state.portfolioReducer?.analyzedPortfolioDot?.transactions?.cash_out_sek);
  const cash_target_sek = useSelector((state) => state.portfolioReducer?.analyzedPortfolioDot?.transactions?.cash_target_sek);

  useEffect(() => {
    if (optAlert?.flag) setModalCancelVisible(true);
  }, [optAlert]);

  const optimalPortfolio = sessionStorage.getItem('selectedPortfolio')
    ? JSON.parse(sessionStorage.getItem('selectedPortfolio'))
    : null;

  useEffect(() => {
    if (precandidate) {
      const data = precandidate?.contents.slice();
      setTableData(data);
    }
  }, [precandidate]);

  const totalCashes = useMemo(() => {
    if (precandidate) {
      const total = precandidate.cashes.reduce((acc, current) => acc + current.value, 0);
      console.log('🚀 ~ totalCashes ~ total:', total);
      const weight = total * 100 / precandidate?.total_value;
      console.log('🚀 ~ totalCashes ~ weight:', weight);
      return { value: total, weight };
    } else {
      return { value: 0, weight: 0 };
    }
  }, [precandidate]);

  const cancelWithoutChange = () => {
    const path = optAlert.path;
    setOptStart(false);
    closeModal();
    history.push(path);
  };

  const handleReset = () => {
    setOptAlert({ flag: false, path: '' });
    setOptStart(false);
    history.push(`/clients/${userId}/portfolio`);
  };

  const handleBack = () => {
    setOptStart(false);
    sessionStorage.removeItem('selectedPortfolio');
    // dispatch(clearPortfolioProspecting());
    setPDFSource([]);
    history.goBack();
  };

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const closeFinishModal = () => {
    setModalFinishVisible(false);
  };

  const handleFinish = () => {
    setModalFinishVisible(true);
  };

  const cancelWithoutReport = () => {
    setModalFinishVisible(false);
    history.push(`/clients/${userId}/portfolio`);
  };

  const handleSaveReport = () => {
    const container6 = document
      .getElementById('pdf-part-6')
      ?.innerHTML.replaceAll('"', '\'');
    const partSix = `<div class='opt-charts-wrapper' id='pdf-part-6'>${container6}</div>`;
    const parts = {
      ...pdfSource,
      page6_table: partSix,
    };

    setOptStart(false);
    setModalFinishVisible(false);
    dispatch(
      sendDataPDFportfolio({
        history,
        clientID: userId,
        portfolioId,
        array: { prospect_portfolio_id: optimalPortfolio.name, precandidate_portfolio_id: precandidate?.id, parts },
      })
    );
    setPDFSource(null);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 'calc(100vh - 80px)',
      }}
    >
      <div className="top-btn-wrapper">
        <Button
          className="portfolio-details-btn portfolio-export-btn"
          onClick={handleReset}
        >
          {t('CANCEL')}
        </Button>
        <Button
          className="portfolio-details-btn optim-change-btn"
          onClick={handleBack}
        >
          {t('BACK_TO_SETTINGS')}
        </Button>
        <Button
          onClick={handleFinish}
          className="btn-edit-save optim-continue-btn"
        >
          {t('FINISH')}
        </Button>
      </div>
      <div className="opt-charts-wrapper" id="pdf-part-6">
        <Container widthP={100} mb="0" padding="0">
        <Row className="opt-title-wrap" style={{ padding: '31px 105px 0 40px' }}>
            <Col>
              <h2 className="opt-paper-title">{t('CASH_REMAINING')}</h2>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <span className="holdings-value">
                {twoDecimalWithSpaces(cash_target_sek)}kr
              </span>
            </Col>
          </Row>
        <Row className="opt-title-wrap" style={{ padding: '0 105px 31px 40px' }}>
            <Col>
              <h2 className="opt-paper-title">{t('CASH_WITHDRAWAL')}</h2>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <span className="holdings-value">
                {twoDecimalWithSpaces(cash_out_sek)}kr
              </span>
            </Col>
          </Row>
        </Container>
        <Container widthP={100} mb="0" padding="0">
          <Row className="opt-title-wrap" style={{ padding: '31px 40px 0' }}>
            <Col>
              <h2 className="opt-paper-title">{t('SUGGESTED_PORTFOLIO')}</h2>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <span className="holdings-text">{t('TOTAL')}</span>
              <span className="holdings-value">
                {twoDecimalWithSpaces(precandidate?.total_value - totalCashes.value)}kr
              </span>
              <Tooltip
                placement="topLeft"
                overlayStyle={{ width: '100%', borderRadius: '14px' }}
                title={
                  <span style={{ width: '100%', color: '#000000' }}>
                    {t('HOLDINGS_FEE_TOOLTOP')}
                  </span>
                }
                color="white"
              >
                <span className="holdings-value-fee">
                  {twoDecimalWithSpaces(precandidate?.total_fee)}%
                </span>
              </Tooltip>
            </Col>
          </Row>
          <PortfolioTableOptimal
            optimalPortfolio={tableData}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
          />
        </Container>
      </div>

      <Modal
        width={650}
        className="modal-content-paper"
        open={modalCancelVisible}
        onCancel={closeModal}
        footer={null}
        zIndex={1620}
      >
        <Row justify="center">
          <Col className="modal-title">{t('WARNING_OPT')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col span={18}>
            <p className="modal-text">{t('WARNING_OPT_TEXT')}</p>
          </Col>
        </Row>

        <Row className="modal-button-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={cancelWithoutChange}
          >
            {t('LEAVE_OPTIMIZATION_FLOW')}
          </Button>
          <Button className="modal-action-btn save-btn" onClick={closeModal}>
            {t('CONTINUE_OPTIMIZATION')}
          </Button>
        </Row>
      </Modal>
      <Modal
        width={650}
        className="modal-content-paper"
        open={modalFinishVisible}
        onCancel={closeFinishModal}
        footer={null}
        zIndex={1620}
      >
        <Row justify="center">
          <Col className="modal-title">{t('WARNING_OPT')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col span={18}>
            <p className="modal-text">{t('REPORT_TEXT')}</p>
          </Col>
        </Row>

        <Row className="modal-button-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={cancelWithoutReport}
          >
            {t('CANCEL')}
          </Button>
          <Button
            className="modal-action-btn save-btn"
            onClick={handleSaveReport}
          >
            {t('CREATE_REPORT')}
          </Button>
        </Row>
      </Modal>
      <MainFooter />
    </div>
  );
};

export default StepSuggestedPortfolio;

StepSuggestedPortfolio.propTypes = {
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  pdfSource: PropTypes.object,
  setPDFSource: PropTypes.func,
  settings: PropTypes.object,
  setOptimalPortfolio: PropTypes.func,
  optAlert: PropTypes.object,
  setOptAlert: PropTypes.func,
  setOptStart: PropTypes.func,
};
