import { actionCreator } from '../../../helpers/actionCreator';

export const GET_USERS = actionCreator('GET_USERS');

export const GET_CLIENT = actionCreator('GET_CLIENT');

export const CREATE_CLIENT_ACCOUNT = actionCreator('CREATE_CLIENT_ACCOUNT');

export const SET_CLIENT_TYPE = actionCreator('SET_CLIENT_TYPE');

export const UPDATE_CLIENT_PHONE = actionCreator('UPDATE_CLIENT_PHONE');

export const DELETE_CLIENT_PHONE = actionCreator('DELETE_CLIENT_PHONE');

export const GET_REPRESENTATIVE = actionCreator('GET_REPRESENTATIVE');

export const ADD_REPRESENTATIVE = actionCreator('ADD_REPRESENTATIVE');

export const ADD_REPRESENTATIVE_DOC = actionCreator('ADD_REPRESENTATIVE_DOC');

export const UPDATE_REPRESENTATIVE = actionCreator('UPDATE_REPRESENTATIVE');

export const DELETE_REPRESENTATIVE = actionCreator('DELETE_REPRESENTATIVE');

export const SET_CLIENT_INFORMATION = actionCreator('SET_CLIENT_INFORMATION');

export const UPDATE_CLIENT_INFO = actionCreator('UPDATE_CLIENT_INFO');

export const UPDATE_CLIENT_INFO_CLEAR = actionCreator('UPDATE_CLIENT_INFO_CLEAR');

export const SET_CLIENT_PHONE = actionCreator('SET_CLIENT_PHONE');

export const SET_CLIENT_ADDRESS = actionCreator('SET_CLIENT_ADDRESS');

export const SET_CLIENT_MARITAL = actionCreator('SET_CLIENT_MARITAL');

export const SET_CLIENT_SCORE = actionCreator('SET_CLIENT_SCORE');

export const SET_CLIENT_DATA = actionCreator('SET_CLIENT_DATA');

export const GET_CLIENT_DATA = actionCreator('GET_CLIENT_DATA');

export const SEND_INVITATION = actionCreator('SEND_INVITATION');

export const GET_INVITATION_INFO = actionCreator('GET_INVITATION_INFO');

export const ACCEPT_INVITATION = actionCreator('ACCEPT_INVITATION');

export const SET_CLIENT_RISK_LEVEL = actionCreator('SET_CLIENT_RISK_LEVEL');

export const SEND_CLIENT_APPROVE = actionCreator('SEND_CLIENT_APPROVE');

export const SET_CLIENT_FEES = actionCreator('SET_CLIENT_FEES');

export const GET_CLIENT_FEES = actionCreator('GET_CLIENT_FEES');

export const DELETE_CLIENT = actionCreator('DELETE_CLIENT');

export const CREATE_FINPLAN = actionCreator('CREATE_FINPLAN');

export const GET_FINPLAN = actionCreator('GET_FINPLAN');

export const PATCH_FINPLAN = actionCreator('PATCH_FINPLAN');

export const DELETE_FINPLAN = actionCreator('DELETE_FINPLAN');

export const GET_SUGGESTIONS_PORTFOLIO = actionCreator('GET_SUGGESTIONS_PORTFOLIO');

export const APPROVE_SUGGESTIONS_PORTFOLIO = actionCreator('APPROVE_SUGGESTIONS_PORTFOLIO');

export const DELETE_SUGGESTIONS_PORTFOLIO = actionCreator('DELETE_SUGGESTIONS_PORTFOLIO');

export const GET_APPROVED_PORTFOLIO = actionCreator('GET_APPROVED_PORTFOLIO');

export const GET_EXECUTED_PORTFOLIO = actionCreator('GET_EXECUTED_PORTFOLIO');

export const GET_IMPLEMENTED_PORTFOLIO = actionCreator('GET_IMPLEMENTED_PORTFOLIO');

export const GET_ALL_APPROVED_PORTFOLIO = actionCreator('GET_ALL_APPROVED_PORTFOLIO');

export const GET_ALL_EXECUTED_PORTFOLIO = actionCreator('GET_ALL_EXECUTED_PORTFOLIO');

export const GET_ALL_IMPLEMENTED_PORTFOLIO = actionCreator('GET_ALL_IMPLEMENTED_PORTFOLIO');

export const GET_CANDIDATE_TRANSACTIONS = actionCreator('GET_CANDIDATE_TRANSACTIONS');

export const EXECUTE_PORTFOLIO = actionCreator('EXECUTE_PORTFOLIO');

export const DOWNLOAD_PDF_REPOR = actionCreator('DOWNLOAD_PDF_REPOR');

export const GET_ONDOARDING_STATUS = actionCreator('GET_ONDOARDING_STATUS');

export const POST_ONDOARDING_DATA = actionCreator('POST_ONDOARDING_DATA');

export const DEL_ONDOARDING = actionCreator('DEL_ONDOARDING');

export const SET_ONDOARDING_VERIFED = actionCreator('SET_ONDOARDING_VERIFED');

export const POST_ONDOARDING_AUDIO = actionCreator('POST_ONDOARDING_AUDIO');

export const CLEAR_ONDOARDING = actionCreator('CLEAR_ONDOARDING');

export const GET_ONDOARDING_RISK_PROFILE = actionCreator('GET_ONDOARDING_RISK_PROFILE');

export const UPLOAD_ONDOARDING_DOCUMENT = actionCreator('UPLOAD_ONDOARDING_DOCUMENT');

export const GET_ONDOARDING_DOCUMENTS_LIST = actionCreator('GET_ONDOARDING_DOCUMENTS_LIST');

export const DELETE_ONDOARDING_DOCUMENT = actionCreator('DELETE_ONDOARDING_DOCUMENT');

export const UPLOAD_ONDOARDING_AUDIO = actionCreator('UPLOAD_ONDOARDING_AUDIO');