/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Button, Col, Divider, Form, Radio, Row, Space } from 'antd';
import Container from '../../../components/Container';

import { decimalSeparator } from '../../../helpers/decimalSeparator';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import CustomInputNumber from '../../../components/CustomInputNumber/CustomInputNumber';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import CustomRadioButtom from '../../../components/CustomRadioButtom/CustomRadioButtom';
import MainFooter from '../../../components/MainFooter/MainFooter';

import { numberWithSpaces } from '../../../helpers/formatInteger';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import { clearPortfolioProspecting } from '../../../data/store/portfolio/portfolioActions';

export const StepSettings = ({ id, settings, portfolioId, setSettings, setOptStart }) => {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [isPropuctFees, setIsPropuctFees] = useState(false);
  const [isDepositFees, setIsDepositFees] = useState(false);
  const [isAdvisoryFees, setIsAdvisoryFees] = useState(false);
  const [clientType, setClientType] = useState(null);

  const minCash = Form.useWatch('min_cash_per', form);
  const { portfolioById } = useSelector((state) => state.portfolioReducer);

  const initValue = {
    include_financial_plan: '0',
    monetary_basis: '1',
    fee_flags: {
      include_product_fees: false,
      include_deposit_fees: false,
      include_advisory_fees: false
    },
    fee_data: {
      deposit_fee: '',
      advisory_fee: ''
    },
    typeOfClient: ''
  };

  const totalCashes = useMemo(() => {
    let persent = 0;
    if (Object.keys(portfolioById || {})?.length && portfolioById?.total_value) {
      if (minCash > 0 && minCash <= 100) {
        persent = minCash;
      }

      return Math.round(portfolioById?.total_value * persent / 100);
    } else {
      return 0;
    }
  }, [portfolioById, minCash]);

  useEffect(() => {
    dispatch(clearPortfolioProspecting());
    setOptStart(false);
    const newData = settings || initValue;
    form.setFieldsValue(newData);
    setClientType(newData?.typeOfClient ?? null);
    setIsPropuctFees(
      newData.fee_flags.include_product_fees ?? true
    );
    setIsDepositFees(
      newData.fee_flags.include_deposit_fees ?? true
    );
    setIsAdvisoryFees(
      newData.fee_flags.include_advisory_fees ?? true
    );
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleNext = async () => {
    await form.validateFields();
    await form.submit();
  };

  const handleChangeCheckBox = (event) => {
    const { id } = event.target;
    if (id === 'fee_flags_include_product_fees') {
      setIsPropuctFees(event.target.checked);
    }
    if (id === 'fee_flags_include_deposit_fees') {
      if (!event.target.checked) {
        const fields = form.getFieldsValue();
        const { fee_data } = fields;
        if (fee_data && Object.hasOwn(fee_data, 'deposit_fee')) {
          fee_data.deposit_fee = '';
          form.setFieldsValue({ ...fields, fee_data });
        }
      }
      setIsDepositFees(event.target.checked);
    }
    if (id === 'fee_flags_include_advisory_fees') {
      if (!event.target.checked) {
        const fields = form.getFieldsValue();
        const { fee_data } = fields;
        if (fee_data && Object.hasOwn(fee_data, 'advisory_fee')) {
          fee_data.advisory_fee = '';
          form.setFieldsValue({ ...fields, fee_data });
        }
      }
      setIsAdvisoryFees(event.target.checked);
    }
  };

  const handleChangeRadio = async (event) => {
    const { value } = event.target;
    setClientType(value);
    if (value === 'existing') {
      const data = await form.getFieldsValue();
      form.setFieldsValue({
        ...data,
        fee_data: {
          deposit_fee: '',
          advisory_fee: '',
        },
      });
    }
  };

  const onSubmit = (data) => {
    try {
      if (Object.keys(data)?.length && data?.monetary_basis) {
        if (!data?.withdrawal_amount) data.withdrawal_amount = null;
        if (!data?.deposit_amount) data.deposit_amount = null;
        const newData = { ...data };
        newData.reserved_amount = totalCashes;
        setSettings(newData);
        history.push(`/clients/${id}/optimization/${portfolioId}/portfolioOptimization`);
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  const depositFee = Form.useWatch(['fee_data', 'deposit_fee'], form);
  const advisoryFee = Form.useWatch(['fee_data', 'advisory_fee'], form);
  const monetaryBasis = Form.useWatch(['monetary_basis'], form);
  const includeFP = Form.useWatch(['include_financial_plan'], form);

  const validation = useMemo(() => {
    const isValidOne = !isDepositFees && !isAdvisoryFees;
    const isValidTwo =
      (isDepositFees && clientType === 'existing') ||
      (isDepositFees &&
        !isAdvisoryFees &&
        clientType === 'potential' &&
        depositFee > 0);
    const isValidThree =
      (isAdvisoryFees && clientType === 'existing') ||
      (isAdvisoryFees &&
        !isDepositFees &&
        clientType === 'potential' &&
        advisoryFee > 0);
    const isValidFour =
      isAdvisoryFees &&
      isDepositFees &&
      clientType === 'potential' &&
      advisoryFee > 0 &&
      depositFee > 0;
    const response = !(isValidOne || isValidTwo || isValidThree || isValidFour) || !monetaryBasis || !includeFP;
    return response;
  }, [depositFee, advisoryFee, monetaryBasis, includeFP]);

  const handleReset = () => {
    history.push(`/clients/${id}/portfolio`);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 'calc(100vh - 80px)'
      }}>
      <div>
        <div className="top-btn-wrapper">
            <Button
              className="portfolio-details-btn portfolio-export-btn"
              onClick={handleReset}
            >
              {t('CANCEL')}
            </Button>
            <Button
              disabled={validation}
              onClick={handleNext}
              className="btn-edit-save optim-continue-btn">
              {t('CONTINUE')}
            </Button>
          </div>
          <Row
              justify={'space-between'}
              align={'middle'}
              style={{ padding: '0 40px' }}
            >
              <Col>
                <h2
                  style={{ padding: '36px 0 0', margin: '0' }}
                  className="portfolio-header-title">
                  {t('PORTFOLIO')}<span className='orang-dots'>:</span>{t('OPTIMIZATION')}
                </h2>
              </Col>
            </Row>
        <div className="settings-inner-wrapper">
          <Form
            className="optimization-settings-form"
            form={form}
            onFinish={onSubmit}
          >
          <Container widthP={100} mb="24px" padding="30px 40px">
              <Row
                justify={'space-between'}
                align={'middle'}
                className='settings-row'
                >
                <Col>
                  <p className='settings-point-text'>
                    {t('SHOULD_ANY_AMOUNT')}
                  </p>
                </Col>
                <Col className='settings-withdrawn-input-wrapper'>
                  <Form.Item
                    name={'withdrawal_amount'}
                    className="m-top-12 m-bottom-0"
                    >
                    <CustomInputNumber
                      placeholder='0,00'
                      width={'172px'} className="custom-input"
                      formatter={(value) => !value ? '' : numberWithSpaces(value) }
                      parser={(value) => value ? value.replace(/( *)/g, '').replace(/,/g, '.') : ''}
                      />
                  </Form.Item>
                  <p>SEK</p>
                </Col>
              </Row>
            </Container>
            <Container widthP={100} mb="24px" padding="30px 40px">
              <Row
                justify={'space-between'}
                align={'middle'}
                className='settings-row'
                >
                <Col>
                  <p className='settings-point-text'>
                    {t('SHOULD_ANY_AMOUNT_DEPOSITED')}
                  </p>
                </Col>
                <Col className='settings-withdrawn-input-wrapper'>
                  <Form.Item
                    name={'deposit_amount'}
                    className="m-top-12 m-bottom-0"
                    >
                    <CustomInputNumber
                      placeholder='0,00'
                      width={'172px'} className="custom-input"
                      formatter={(value) => !value ? '' : numberWithSpaces(value) }
                      parser={(value) => value ? value.replace(/( *)/g, '').replace(/,/g, '.') : ''}
                      />
                  </Form.Item>
                  <p>SEK</p>
                </Col>
              </Row>
            </Container>
            <Container widthP={100} mb="24px" padding="30px 40px">
              <Row
                justify={'space-between'}
                align={'middle'}
                className='settings-row'>
                <Col>
                  <p className='settings-point-text'>
                    {t('MINIMUM_CASH_LEVEL')}
                  </p>
                </Col>
                <Col className='currency-sek-wrapper'>
                  <div>
                  <Form.Item
                    className='custom-required'
                    name='min_cash_per'
                    required={false}
                  >
                    <CustomInputNumber
                      width={'170px'}
                      percent={true}
                      className='custom-inputnumber'
                      decimalSeparator={decimalSeparator()}
                      min={0}
                      max={100}
                      placeholder='0,00'
                    />
                  </Form.Item>
                  </div>
                  <div>
                    <p className='currency-sek-text'>{numberWithSpaces(totalCashes)} SEK</p>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container widthP={100} mb="24px" padding="30px 40px">
              <Row
                justify={'space-between'}
                align={'middle'}
                className='settings-row'
                >
                <Col>
                  <p className='settings-point-text'>
                    {t('INCLUDE_THE_FINANCIAL_PLAN')}
                  </p>
                </Col>
                <Col>
                  <Form.Item
                    name={'include_financial_plan'}
                    className="m-top-12 m-bottom-0">
                    <Radio.Group
                      className="portfolio-form-radio"
                    >
                      <Space direction="horizontal">
                        <CustomRadioButtom value="1">{t('YES')}</CustomRadioButtom>
                        <CustomRadioButtom value="0">{t('NO')}</CustomRadioButtom>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Container>
            <Container widthP={100} mb="24px" padding="30px 40px">
            <Row
                justify={'space-between'}
                align={'middle'}
                className='settings-row'
                >
                <Col>
                  <p className='settings-point-text'>
                    {t('MONETARY_BASIS')}
                  </p>
                </Col>
                <Col>
                  <Form.Item
                    name={'monetary_basis'}
                    className="m-top-12 m-bottom-0"
                  >
                    <Radio.Group
                      className="portfolio-form-radio"
                    >
                      <Space direction="horizontal">
                        <CustomRadioButtom value="1">{t('NOMINAL')}</CustomRadioButtom>
                        <CustomRadioButtom value="2">{t('REAL')}</CustomRadioButtom>
                      </Space>
                    </Radio.Group>
                  </Form.Item>

                </Col>
            </Row>
            </Container>
            <Container widthP={100} mb="24px" padding="30px 40px" >
            <Row
                justify={'space-between'}
                align={'middle'}
                className='settings-row'>
                <Col>
                  <p className='settings-point-text'>
                    {t('LIKE_TO_INCLUDE')}
                  </p>
                </Col>
                <Col>
                  <Row gutter={36}>
                    <Col>
                      <Form.Item
                        name={['fee_flags', 'include_product_fees']}
                        valuePropName="checked"
                        className="m-top-12 m-bottom-0"
                      >
                        <CustomCheckbox
                          className=''
                          onChange={handleChangeCheckBox}>
                          {t('PRODUCT_FEES')}
                        </CustomCheckbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name={['fee_flags', 'include_deposit_fees']}
                        valuePropName="checked"
                        className="m-bottom-0"
                      >
                        <CustomCheckbox
                          className=''
                          onChange={handleChangeCheckBox}>
                          {t('DEPOSIT_FEES')}
                        </CustomCheckbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name={['fee_flags', 'include_advisory_fees']}
                        valuePropName="checked"
                        className="m-bottom-0"
                      >
                        <CustomCheckbox
                          className=''
                          onChange={handleChangeCheckBox}>
                          {t('ADVISORY_FEES')}
                        </CustomCheckbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="opt-portfolio-form-container">
                {(isDepositFees || isAdvisoryFees) && (
                  <>
                    <Divider/>
                    <Row gutter={48}>
                      <Col>
                        <label className="opt-portfolio-form-subheading">
                          {t('TYPE_OF_CLIENT')} *
                        </label>
                        <Form.Item
                          name={'typeOfClient'}
                          className="m-top-12 m-bottom-0"
                          required={false}
                        >
                          <Radio.Group
                            onChange={handleChangeRadio}
                            className="portfolio-form-radio"
                            value={clientType}
                          >
                            <Space direction="horizontal">
                              <CustomRadioButtom value="existing">{t('EXISTING')}</CustomRadioButtom>
                              <CustomRadioButtom value="potential">{t('POTENTIAL')}</CustomRadioButtom>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      {clientType === 'potential' && (
                        <>
                          {isDepositFees && (
                            <Col>
                              <label className="opt-portfolio-form-subheading fee">
                                {t('DEPOSIT_OPT_FEE')} *
                              </label>
                              <Form.Item name={['fee_data', 'deposit_fee']}>
                                <CustomInputNumber
                                  width={'360px'}
                                  percent={true}
                                  decimalSeparator={decimalSeparator()}
                                  precision={2}
                                  className=''
                                  placeholder={t('ENTER_FEE')}
                                  size="medium"
                                  min={0.01}
                                  step={0.01}
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {isAdvisoryFees && (
                            <Col>
                              <label className="opt-portfolio-form-subheading fee">
                                {t('ADVISORY_OPT_FEE')} *
                              </label>
                              <Form.Item name={['fee_data', 'advisory_fee']}>
                                <CustomInputNumber
                                  width={'360px'}
                                  percent={true}
                                  decimalSeparator={decimalSeparator()}
                                  precision={2}
                                  className=''
                                  placeholder={t('ENTER_FEE')}
                                  size="medium"
                                  min={0.01}
                                  step={0.01}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                  </>
                )}
              </div>
            </Container>
          </Form>
      </div>
      </div>
      <MainFooter />
    </div>
  );
};

StepSettings.propTypes = {
  id: PropTypes.string,
  portfolioId: PropTypes.string,
  settings: PropTypes.object,
  setSettings: PropTypes.func,
  setOptStart: PropTypes.func,
};
