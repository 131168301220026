export const OnboardingSW = {
  WELCOME: 'Välkommen till Citroneer!',
  WHAT_TYPE_OF_ACCOUNT: 'Viken typ av konto vill du öppna?',
  WANT_TO_START_A_PERSONAL:
    'Vill du starta ett privat konto eller ett företagskonto?',
  PRIVATE_CUSTOMER: 'Privatkund',
  BUSINESS_CUSTOMER: 'Företagskund',
  PRIVAT_ORGANISATION_NUMBER: 'Person-/organisationsnummer',
  PRIVAT_NUMBER: 'Personnummer',
  ORGANISATION_NUMBER: 'Organisationsnummer',
  ENTER_ORGANIZATION_NUMBER: 'Ange person-/organisationsnummer',
  MOVE_ON: 'Gå vidare',
  RFA19: 'Vill du identifiera dig eller skriva under med BankID på den här datorn eller med ett Mobilt BankID?',
  MOBILEID_HEADER: 'Starta BankID-appen på din telefon eller surfplatta. Tryck på QR-kodknappen i appen. Rikta kameran mot QR-koden nedan.',
  USE_BANKID: 'Använd BankID på den här enheten',
  THE_LOGIN_FAILED: 'Inloggningen misslyckades',
  LOGIN_FAILED_TEXT: 'Det gick inte att skanna QR-koden. Starta BankID-appen och skanna QR-koden. Kontrollera att BankID-appen är uppdaterad. Om du inte har BankID-appen behöver du installera den och beställa ett BankID från din internetbank. Installera appen från din appbutik eller https://install.bankid.com.',
  TRY_AGAIN: 'Försök igen',
  LOGIN_WITH_BANKID: 'Logga in med Testa BankID',
  TYPE_YOUR_SECURITY: 'Ange din säkerhetskod i BankID-appen och välj Identifiera.',
  WHAT_TYPE_OF_ACCOUNT_IS: 'Vilken typ av konto behövs för din kund?',
  WHAT_TYPE_ACCOUNT_OPEN: 'Vilken typ av konto vill du öppna?',
  DO_YOU_WANT_TO_START: 'Vill du starta ett personligt konto eller ett företagskonto?',
  PLEASE_PROVIDE_MEETING: 'Ange hur du möter din kund',
  WHAT_TYPE_MEETING: 'Vilken typ av möte planerar du?',
  ONLINE: 'Online',
  OFFLINE: 'Off-line',
  PLEASE_PROVIDE_MEETING_LINK: 'Ange en möteslänk eller planera det nu:',
  CONFIRM: 'Bekräfta',
  PLAN_MEETING: 'Planera möte',
  PLEASE_USE_SCRIPT: 'Använd Script för att göra mötet mer produktivt',
  YOUR_MEETING_DETAILS: 'Dina mötesdetaljer',
  MEETING_LINK: 'Möteslänk',
  COPIED: 'Kopierade!',
  WHEN: 'När',
  GUESTS: 'Gäster',
  SCRIPT: 'Manus',
  RESCHEDULE: 'Boka om',
  TO_MAKE_MEETING_TRANSCRIPTION: 'Det tar lite tid att göra mötestranskriberingen, vi meddelar dig när det är klart',
  PROCESSING: 'Bearbetning',
  MEETING_PROCESSING_LEVEL: 'Mötesbehandlingsnivå',
  MEETING_ID: 'Mötes-ID',
  MEETING_TRANSCRIBING: 'Möte transkriberar...',
  CHECK_YOUR_CLIENT_INFO: 'Kontrollera din kundinformation och Singing informationen',
  TYPE_OF_ACCOUNT: 'Typ av konto',
  CLIENT_INFORMATION: 'Kundinformation',
  PURPOSE_MATTER: 'Syfte & Materia',
  FINANCIAL_SITUATION: 'Ekonomisk situation',
  BASIC_PREREQUISITES_FOR_MANAGEMENT: 'Grundläggande förutsättningar för ledning',
  KNOW_YOUR_CLIENT: 'Känn din klient',
  RISK_TOLERANCE: 'Risktolerans',
  RISK_PROFILE: 'Riskprofil',
  EXPERIENCE_BACKGROUND: 'Erfarenhetsbakgrund',
  TYPE_OF_ADVICE: 'Typ av råd',
  ASSIGNMENT_AGREEMENT: 'Uppdragsavtal',
  SIGNING: 'Signering',
  SIGNING_CHECK_ONE: 'Jag samtycker härmed till att Citroneer behandlar personuppgifter som lämnas i samband med att detta avtal ingås. personuppgifterna används av Citroneer för öppnandet av depån och i andra fall i samband med vad som framgår av detta avtal',
  SIGNING_CHECK_TWO: 'Jag har läst relevant informationsmaterial och faktablad.',
  SIGNING_CHECK_THREE: 'Jag samtycker till att mina personuppgifter lagras och hanteras av Citroneer för administration.',
  SIGNING_CHECK_FOUR: 'Jag har förstått att jag i undantagsfall riskerar att förlora hela eller delar av det investerade kapitalet.',
  MEETING_INFORMATION: 'Mötesinformation',

  DATE_OF_COUNSELING: 'Datum för rådgivning',
  THE_EXECUTION_CONSULTANCY: 'Utförandet av konsultverksamheten',
  BASIC_INFORMATION: 'Grundläggande information',
  SURNAME: 'Efternamn',
  ENTER_SURNAME: 'Ange efternamn',
  SOCIAL_SECURITY_NUMBER: 'Personnummer',
  ENTER_SOCIAL_SECURITY_NUMBER: 'Ange personnummer',
  ONBOARDING_SCRIPT: 'Onboarding Script',
  FIND_ALL_QUESTIONS: 'Du hittar alla frågor här:',
  NEXT: 'Nästa',
  WHAT_IS_THE_PURPOSE_GOAL: 'Vad är syftet och målet med investeringarna?',
  YOUR_ANSWER: 'Ditt svar...',
  HEART_AFFAIRS: 'Har du några "ärenden nära ditt hjärta" som du vill ska beaktas när du väljer investeringar?',
  QUALITATIVE_GOALS: 'Utöver avkastningen, finns det kvalitativa mål med investeringarna?',
  NONE_RETURN_FOCUS: 'Ingen - Återgång är i fokus.',
  BASIC_SUSTAINABILITY: 'Basic Sustainability (ESG)',
  SUSTAINABILITY_IN_DEPTH: 'Hållbarhet i djupet (SRI)',
  STRONG_IMPACT: 'Stark påverkan (Impact Investing)',
  SUSTAINABLE_VIEW: 'Vilket påstående nedan beskriver bäst din syn på investeringar och hållbarhetsfrågor?',
  MY_FOCUS_IN_INVESTMENTS: 'Mitt fokus på investeringar är risk och avkastning',
  I_WANT_INTEGRATE_SUSTAINABILITY: 'Jag vill integrera hållbarhetsaspekter där det är möjligt utan att avsäga mig risk- och avkastningsutsikter',
  SUSTAINABILITY_IS_VERY_IMPORTANT: 'Hållbarhet är en mycket viktig fråga i min portfölj och jag kan överväga att sänka kraven på risk och avkastningsutsikter för att utesluta vissa branscher',
  THERE_OTHER_ASPECTS_WORKING: 'Finns det några andra aspekter av att arbeta med portföljen som Citroneer bör vara medveten om?',

  REVENUE_AND_EXPENDITURE: 'Intäkter och utgifter',
  INCOME_EMPLOYMENT: 'Vad har du för inkomst av anställning',
  DO_YOU_HAVE_OTHER_INCOME: 'Har du någon annan inkomst',
  EXPENDITURE_QUESTION: 'Vilka är dina utgifter',
  WHAT_YOUR_SURPLUS: 'Vad är ditt överskott',
  ADDITIONAL_INFORMATION_ABOUT_REVENUE: 'Ytterligare information om inkomster och utgifter',
  YOUR_TEXT: 'Din text..',
  YOUR_FINANCIAL_INVESTMENTS: 'Vilka är dina finansiella investeringar',
  CASH_EQUIVALENTS: 'Vilka likvida medel har du',
  PENSION_SAVINGS: 'Vad är ditt pensionssparande',
  ADDITIONAL_ABOUT_FINANCIAL_ASSETS: 'Ytterligare information om finansiella tillgångar',
  OTHER_ASSETS: 'Övriga finansiella tillgångar',
  HAVE_ANY_OTHER_ASSETS: 'Har du några andra tillgångar',
  FIXED_ASSETS: 'Fasta tillgångar',
  ONB_HOUSING: 'Vad är det ungefärliga värdet på dina bostadstillgångar',
  ONB_HOUSING_2: 'Vad är axeln på dina bostadstillgångar',
  OTHER_FIXED_ASSETS: 'Har du några andra anläggningstillgångar',
  ADD_ABOUT_FIXED_ASSETS: 'Ytterligare information om anläggningstillgångar',
  SHOULDER: 'Axel',
  OTHER_LIABILITIES: 'Har du några andra skulder',
  ADD_INFORMATION_SHOULDER: 'Ytterligare information om skuldra',
  SCOPE_INVESTABLE_CAPITAL: 'Omfattning och investerbart kapital',
  NON_COVERED_ELEMENTS: 'Finns det moment som inte bör omfattas av råden?',
  CAPITAL_NOT_COVERED_ADVICE: 'Kapital som inte omfattas av rådgivningen',
  INVESTABLE_CAPITAL: 'Investerbart kapital',
  WHAT_YOUR_INVESTABLE_CAPITAL: 'Vad är ditt investeringsbara kapital',
  BASIC_PREREQUISITES_MANAGEMENT: 'Grundläggande förutsättningar för ledning',
  ABLE_TO_LIQUIDATE_THE_HOLDINGS: 'Finns det behov av att kunna avveckla innehavet med kort varsel?',
  ANY_THOUGHTS_ON_HOW_FUTURE: 'Finns det några tankar om hur framtida generationer kommer att ta del av portföljens avkastning som Citroneer borde känna till?',
  TIME_HORIZON: 'Tidshorisont',
  COMMENT_TIME_HORIZON: 'Kommentar tidshorisont',
  ADD_COMMENT_TIME_HORIZON: 'Ytterligare kommentar om tidshorisont',
  BUFFER_CAPITAL: 'Buffertkapital',
  CITIZENSHIP_TAX_RESIDENCE: 'Medborgarskap & skattehemvist',
  OFFLINE_MEETING: 'Offlinemöte',
  ONLINE_MEETING: 'Onlinemöte',
  PHONE_MEETING: 'Telefonmöte',
  SELECT_YOUR_OPTION: 'Välj ditt alternativ',
  CITIZENSHIP: 'Vad är ditt medborgarskap',
  SECOND_CITIZENSHIP: 'Har du andra medborgarskap',
  TAX_RESIDENCE: 'Vad är din skatterättsliga hemvist',
  UPLOAD_COPY_YOUR_PASSPORT: 'Ladda upp en kopia av ditt pass eller ID-kort',
  ONB_UPLOAD_DOCUMENT: 'Ladda upp dokument',
  NO_DOCUMENT_ADDED: 'Inget dokument tillagt',
  ID_DOC_NUMBER: 'Ange ID-dokumentets kontrollnummer',
  ID_DOC_CONTROL_NUMBER: 'ID-handlingskontrollnummer',
  FILL_EXPIRY_DATE_ID_DOCUMENT: 'Ange utgångsdatum för ID-handlingen',
  ABROAD: 'Utlandet',
  USA_RELATIONSHIPS: 'Har du någon relation med USA?',
  USA_RELATIONSHIPS_TYPE: 'Vad är ditt förhållande till USA?',
  ONB_UPLOAD_DOCUMENTS: 'Ladda upp dokument',
  FOREIGN_TRANSACTIONS: 'Brukar du göra transaktioner med utlandet?',
  TRANSACTIONS_AMOUNT: 'Hur stora är dina transaktioner med utlandet?',
  PURPOSE_OF_ENGAGEMENT: 'Syftet med engagemanget',
  SAVINGS_PURPOSE: 'Vad är syftet med ditt sparande och/eller dina investeringar?',
  DESCRIBE_OTHER_PURPOSE_TITLE: 'Om alternativet "Annat (beskriv)" valdes i frågan',
  DESCRIBE_OTHER_PURPOSE_QUESTION: 'Ange en beskrivning av det andra alternativet',
  SAVINGS_INVESTMENTS: 'Sparande/investeringar',
  FINANCIAL_SECURITY: 'Finansiell säkerhet',
  PENSION: 'Pension',
  PRIVATE_CONSUMPTION: 'Privat konsumtion',
  GIFT_CLOSE_RELATIVE: 'Gåva till en nära anhörig',
  BENEFICIARY: 'Förmånstagare',
  OTHER_DESCRIBE: 'Annat (beskriv)',
  ON_HIS_OWN_BEHALF: 'För någon annan genom fullmakt',
  SOMEONE_ELSE_POWER_ATTORNEY: 'För någon annan genom fullmakt',
  BEHALF: 'För vems räkning investerar du?',
  COMMENT_ON_PROXY: 'Kommentar till fullmakten',
  COMMENT_ON_PROXY_TITLE: 'Om i frågan valdes alternativet "För någon annan genom fullmakt".',
  COMMENT_ON_PROXY_QUESTION: 'Lämna en kommentar om fullmakten',
  COMMENT_ON_BEHALF: 'Beskrivning av det andra alternativet',
  COMMENT_ON_BEHALF_TITLE: 'Om alternativet "Annat (beskriv)" valdes i frågan',
  COMMENT_ON_BEHALF_QUESTION: 'Ange en beskrivning av det andra alternativet',
  UPLOAD_DOCUMENTS_PROVING: 'Upload documents proving power of attorney',
  MAIN_OCCUPATION: 'Huvudsaklig sysselsättning',
  MAIN_OCCUPATION_TITLE: 'Om i frågan "main_occupation" valdes alternativet "Annat (beskriv)".',
  MAIN_OCCUPATION_QUESTION: 'Ange en beskrivning av det andra alternativet',
  WHAT_YOUR_MAIN_OCCUPATION: 'Vilken är din huvudsakliga sysselsättning?',
  EMPLOYEE: 'Anställd',
  SELF_EMPLOYED: 'Egen företagare',
  RETIRED: 'Pensionerad',
  STUDENT: 'Studerande',
  WORK_SECTOR: 'Inom vilken sektor arbetar du?',
  MONTHLY_INCOME: 'Vad är din ungefärliga månadsinkomst?',
  MONTHLY_INCOME_OVER: 'Över 100 000 kr',
  CAPITAL_TRANSFER: 'Kapitalöverföring',
  MONEY_TRANFER: 'Är det dina pengar som kommer att överföras?',
  WHOSE_MONEY: 'Förklara vems pengar som kommer att överföras',
  MONEY_ORIGIN: 'Varifrån kommer de pengar och/eller värdepapper du sätter in hos Citroneer?',
  MONEY_ORIGIN_TITLE: 'Om alternativet "Annat (beskriv)" valdes i frågan',
  MONEY_ORIGIN_QUESTION: 'Ange en beskrivning av det andra alternativet',
  SALARY_PENSION: 'Lön/Pension',
  SALE_OF_COMPANY: 'Försäljning av företag',
  INHERITANCE_GIFT: 'Arv/gåva',
  SALE_HOME_PROPERTY: 'Försäljning av bostad eller fastighet',
  BANK_CAPITAL: 'Från vilken bank/försäkringsbolag tar du kapital för dina investeringar?',
  BANK_CAPITAL_OPTION_01: 'Bank eller värdepappersinstitut i Sverige',
  BANK_CAPITAL_OPTION_02: 'Försäkringsbolag i Sverige',
  BANK_CAPITAL_OPTION_03: 'Bank eller värdepappersinstitut i annat EU/EES-land',
  BANK_CAPITAL_OPTION_04: 'Försäkringsbolag i annat EU/EES-land',
  BANK_CAPITAL_OPTION_05: 'Bank eller värdepappersinstitut utanför EU/EES',
  BANK_CAPITAL_OPTION_06: 'Försäkringsbolag utanför EU/EES',
  TRANSFER_VALUE: 'Vilket ungefärligt värde kommer du att överföra när du öppnar ditt konto hos Citroneer?',
  TRANSFER_VALUE_OVER: 'Över 5 000 000 kr',
  ESTIMATE: 'Ange uppskattning av det ungefärliga värdet som kommer att överföras när du öppnar konto hos Citroneer',
  ENTER_ESTIMATE: 'Ange uppskattning',
  DEPOSITS_WITHDRAWALS: 'Insättningar, uttag och transaktioner',
  YEARLY_DEPOSIT: 'Vad blir det genomsnittliga värdet av dina insättningar per år?',
  TIME_DEPOSIT_OPENING_ACCOUNT: 'Engångsinsättning endast när du öppnar kontot',
  ENTER_ESTIMATE_AVERAGE: 'Ange uppskattning av medelvärdet av insättningar per år',
  DEPOSIT_FREQUENCY: 'Hur ofta görs insättningar per år?',
  TIMES_0_10: '0 - 10 gånger',
  TIMES_11_50: '11 - 50 gånger',
  TIMES_51_100: '51 - 100 gånger',
  TIMES_MORE_THEN: 'Fler än 100 gånger',
  WITHDRAWAL_FREQUENCY: 'Hur ofta görs uttag per år?',
  INDIVIDUAL_TRANSACTIONS: 'Hur stora enskilda transaktioner i finansiella instrument gör du normalt?',
  ESTIMATE_TRANSACTIONS: 'Ange uppskattning av mängden enskilda transaktioner i finansiella instrument',
  PERSON_VULNERABLE_POSITION: 'Person i en politiskt utsatt position',
  PEP_POSITION: 'Är du själv, eller har du varit, en person i en politiskt utsatt position?',
  PEP_TYPE_TITLE: 'Om kunden är en PEP',
  PEP_TYPE_QUESTION: 'Vilken typ av PEP är du?',
  PEP_TYPE_OPTION_1: 'Statschefen',
  PEP_TYPE_OPTION_2: 'Riksdagsledamöter',
  PEP_TYPE_OPTION_3: 'Statsministern och övriga ministrar i regeringskansliet',
  PEP_TYPE_OPTION_4: 'Domare i Högsta domstolen och Högsta förvaltningsdomstolen',
  PEP_TYPE_OPTION_5: 'Riksrevisorn',
  PEP_TYPE_OPTION_6: 'Riksbankens direktion',
  PEP_TYPE_OPTION_7: 'Ambassadörer',
  PEP_TYPE_OPTION_8: 'Högre officerare (general, generallöjtnant, generalmajor, amiral, viceamiral och konteramiral)',
  PEP_TYPE_OPTION_9: 'VD eller styrelseledamot i statligt ägda företag',
  PEP_TYPE_OPTION_10: 'Person i ledningen för en internationell organisation (styrelseledamöter, generalsekreterare etc.)',
  PEP_TYPE_OPTION_11: 'Ledamot i det politiska partiets styrelse',
  PEP_RELATIVE: 'Har du någon nära dig som är, eller har varit, en politiskt utsatt person?',
  PEP_RELATIVE_TYPE_TITLE: 'Om kunden har nära släktingar som är PEP',
  PEP_RELATIVE_TYPE_QUESTION: 'Vilken typ av PEP är din släkting?',
  EXPECTED_RETURN: 'Vad är den årliga förväntade avkastningen på kapital?',
  EXPECTED_RETURN_OPTION_1: 'I linje med bankkontorsränta',
  EXPECTED_RETURN_OPTION_2: 'Något över bankkontorsränta',
  EXPECTED_RETURN_OPTION_3: 'En klart högre avkastning än bankkontorsränta',
  EXPECTED_RETURN_OPTION_4: 'Det finns ett definierat avkastningsmål',
  RETURN_TARGET: 'Avkastningsmål',
  INVESTMENT_PHILOSOPHY: 'Vilket påstående nedan beskriver bäst din investeringsfilosofi?',
  INVESTMENT_PHILOSOPHY_OPTION_1: 'Fokusera på att kontrollera risken för förlust och acceptera därmed en relativt låg avkastning.',
  INVESTMENT_PHILOSOPHY_OPTION_2: 'Accepterar viss risk för att få högre potential för avkastning.',
  INVESTMENT_PHILOSOPHY_OPTION_3: 'Fokusera på avkastning och kan därmed acceptera stora fluktuationer i investeringarnas värde.',
  MEDIA_INFLUENCE: 'I vilken utsträckning påverkas din syn på investeringar av marknadsnyheter och media?',
  MEDIA_INFLUENCE_OPTION_1: 'Inte signifikant och orsakar inte portföljoro.',
  MEDIA_INFLUENCE_OPTION_2: 'Negativa nyheter innebär ett större engagemang för hur investeringarna går.',
  MEDIA_INFLUENCE_OPTION_3: 'Negativa nyheter innebär att portföljen ändras för att följa experternas råd.',
  MEDIA_INFLUENCE_OPTION_4: 'Ökad uppmärksamhet på portföljen men verkar med is i magen.',
  NEGATIVE_NEWS: 'Hur påverkas din syn på investeringar när media fylls av negativa marknadsnyheter och experter ger investeringstips?',
  NEGATIVE_NEWS_OPTION_1: 'Inte betydande och orsakar inte portföljoro.',
  NEGATIVE_NEWS_OPTION_2: 'Negativa nyheter innebär ett större engagemang för hur investeringarna går.',
  NEGATIVE_NEWS_OPTION_3: 'Negativa nyheter innebär att portföljen ändras för att följa experternas råd.',
  NEGATIVE_NEWS_OPTION_4: 'Ökad uppmärksamhet på portföljen men verkar med is i magen.',
  PORTFOLIO_VALUE_DROP: 'Anta att din portfölj är värd 50 miljoner kronor idag. Hur skulle du reagera om dess värde sjönk till 45 miljoner på 12 månader?',
  PORTFOLIO_VALUE_DROP_OPTION_1: 'En vilja att minska risken i portföljen och till exempel sälja aktier.',
  PORTFOLIO_VALUE_DROP_OPTION_2: 'Portföljen lämnas intakt, men minska utdelningen från portföljen.',
  PORTFOLIO_VALUE_DROP_OPTION_3: 'Kontakta rådgivaren för att diskutera portföljen.',
  PORTFOLIO_VALUE_DROP_OPTION_4: 'Nej dels är det naturligt att ha stora svängningar på marknaderna.',
  PORTFOLIO_VALUE_DROP_OPTION_5: 'En möjlighet att ta vara på och öka risken och till exempel köpa aktier.',
  DOUBLE_VALUE_DROP: 'Vad kommer din reaktion att bli om värdet på din portfölj istället sjunker till 40 miljoner under de kommande 12 månaderna?',
  DOUBLE_VALUE_DROP_OPTION_1: 'No, big and small moves are a natural part of investing.',
  DOUBLE_VALUE_DROP_OPTION_2: 'Contact the advisor to discuss the portfolio.',
  DOUBLE_VALUE_DROP_OPTION_3: 'Small swings I can understand, but big falls definitely stress me out.',
  LOW_VALUE_INCREASE: 'Vad blir din reaktion om Stockholmsbörsen stiger med 20 procent medan din portfölj bara ökar med 5 procent?',
  LOW_VALUE_INCREASE_OPTION_1: 'Frustration över det missade tillfället och att hamna efter börsen.',
  LOW_VALUE_INCREASE_OPTION_2: 'Inte värt att nämna, Stockholmsbörsen har en högre risk än min portfölj.',
  LOW_VALUE_INCREASE_OPTION_3: 'Prata med rådgivaren för att förstå varför portföljen underpresterar.',
  LOW_VALUE_INCREASE_OPTION_4: 'Inga, portföljen är balanserad och kommer inte att följa individuella marknadsrörelser.',
  PORTFOLIO_EVALUATION: 'Jämför du din portfölj med något/hur utvärderar du dina investeringar?',
  PORTFOLIO_EVALUATION_OPTION_1: 'Jag jämför mina resultat med svenska aktier/Stockholmsbörsen.',
  PORTFOLIO_EVALUATION_OPTION_2: 'Jag jämför mina resultat med en global aktieportfölj.',
  PORTFOLIO_EVALUATION_OPTION_3: 'Jag har ett specifikt riktmärke.',
  PORTFOLIO_EVALUATION_OPTION_4: 'Jag jämför min portfölj med en fond/förvaltare.',
  ADDITIONAL_COMMENT: 'Kommentera risk- och avkastningsförväntningar',
  RISK_CLASSIFICATION_PROPOSAL: 'Citroneers riskklassificeringsförslag',
  GENERAL_INFORMATION: 'Allmän information',
  FIN_EDUCATION: 'Har du utbildning och/eller nuvarande eller tidigare yrke som ger kunskap om hur finansiella investeringar och marknader fungerar?',
  TRADING_PERIOD: 'Hur länge har du handlat värdepapper?',
  ASSET_TYPE: 'Vilken typ av tillgångar är du bekant med?',
  KNOWLEDGE_TYPES_ASSETS: 'Kunskap om olika typer av tillgångar',
  ASSET_EXPERIENCE: 'Vilken kunskap och erfarenhetsnivå av handelstillgångar har du? (-- Ingen, -- Grundläggande, -- Omfattande)',
  ASSET_EXPERIENCE_LABEL: 'Kunskap & erfarenhet',
  ASSET_EXPERIENCE_OPTION_1: 'Ingen',
  ASSET_EXPERIENCE_OPTION_2: 'Grundläggande',
  ASSET_EXPERIENCE_OPTION_3: 'Omfattande',
  ASSET_TRAINED: 'Har du utbildats i tillgångskunskap?',
  ASSET_TRAINED_LABEL: 'Kunskap & erfarenhet',
  RISK_UNDERSTANDING: 'Tror rådgivaren att kunden har förstått riskerna med produkten?',
  RISK_UNDERSTANDING_LABEL: 'Rådgivaren menar att kunden har förstått riskerna med produkten',
  LISTED_SHARES: 'Noterade aktier',
  UNLISTED_SHARES: 'Onoterade aktier',
  UCITS_FUNDS: 'UCITS-fonder',
  SPECIAL_FUNDS: 'AIF-fonder/specialfonder',
  LISTED_BONDS: 'Noterade obligationer',
  DEALS_NUMBER: 'Antal affärer under de senaste tre åren',
  UNLISTED_BONDS: 'Onoterade obligationer',
  EQUITY_INDEX_BONDS: 'Aktieindexobligationer',
  HEDGE_FUNDS: 'Hedgefonder',
  CERTIFICATE_OF_CREDIT: 'Kreditbevis',
  AUTOCALLS: 'Autosamtal',
  EXCHANGE_TRADED_FUNDS: 'BF (börshandlade fonder)',
  DERIVATIVES_AND_COMMODITY: 'Derivat och råvarucertifikat',
  ASSETS_TYPE_KNOWLEDGE: 'Ge ytterligare kommentarer om kunskap om olika typer av tillgångar',
  ASSETS_TYPE_KNOWLEDGE_COMMENT: 'Kommentar: kunskap om olika typer av tillgångar',
  KNOWLEDGE_PORTFOLIO_MANAGEMENT: 'Kunskaper om portföljförvaltning',
  MANAGEMENT_EXPERIENCE: 'Vilken kunskap och erfarenhetsnivå av att hantera portfölj har du?',
  MANAGEMENT_EXPERIENCE_LABEL: 'Kunskap & erfarenhet',
  MANAGEMENT_EXPERIENCE_OPTION_1: 'Ingen',
  MANAGEMENT_EXPERIENCE_OPTION_2: 'Grundläggande',
  MANAGEMENT_EXPERIENCE_OPTION_3: 'Omfattande',
  PORTFOLIO_TRAINED: 'Har du utbildats i portföljförvaltning?',
  PORTFOLIO_TRAINED_LABEL: 'Kunden har utbildats',
  PORTFOLIO_UNDERSTANDING: 'Anser rådgivaren att kunden förstår portföljförvaltningen tillräckligt väl?',
  PORTFOLIO_UNDERSTANDING_LABEL: 'Rådgivaren anser att kunden förstår portföljförvaltningen tillräckligt väl',
  PORTFOLIO_MANAGEMENT_ADD: 'Ge ytterligare kommentarer om kunskap om olika typer av tillgångar',
  PORTFOLIO_MANAGEMENT_COMMENT: 'Kommentar: kunskap om portföljförvaltning',
  RISK_OF_LOSS: 'Risk för förlust',
  LOSS_RISK: 'Kan du förlora hela eller delar av ditt investerade kapital om du investerar i finansiella instrument?',
  LOSS_RISK_QUESTION: 'Do you agree with the statement that you can lose all or part of your invested capital if you invest in financial instruments?',
  DOCUMENTATION: 'Dokumentation',
  ACCOUNT_OPENING: 'Kontoöppning',
  RECOMMENDATION: 'Rekommendation',
  ACCOUNT_SELECTION: 'Kontoval',
  STOCK_FUND_ACCOUNT: 'Aktie- och fondkonto',
  INVESTMENT_SAVINGS_ACCOUNT: 'Investeringssparkonto',
  DEPOSIT_CAPITAL_INSURANCE: 'Insättningskapitalförsäkring',
  DEPOT_PENSION_INSURANCE: 'Depå tjänstepensionsförsäkring',
  ADVISER_PROPOSAL_CHANGE: 'Rådgivarens förslag till förändring',
  JUSTIFICATION_GIVEN_ADVICE: 'Motivering för givna råd utifrån kundens önskemål och behov',
  ADVISER_BELIEVE_THAT_CLIENT: 'Anser rådgivaren att klienten har tillräckligt med buffert även efter att klienten följt råden?',
  MOTIVATE: 'Motivera',
  ADVISOR_CONSIDER_RECOMMENDATION: 'Anser rådgivaren att rekommendationen är lämplig med hänsyn till kundens riskaptit, riskkapacitet och investeringshorisont?',
  SUITABILITY_BASED_CUSTOMER: 'Lämplighet utifrån kundens kunskap och erfarenhet',
  SUITABILITY_OPTION_1: 'Rekommendationen är lämplig mot bakgrund av kundens tidigare kunskaper och erfarenheter.',
  SUITABILITY_OPTION_2: 'Rekommendationen är lämplig med bakgrund av kundens tidigare kunskaper och erfarenheter, efter att kunden har utbildats i mötet.',
  SUITABILITY_OPTION_3: 'Rekommendationen är inte lämplig utifrån kundens tidigare kunskaper och erfarenheter.',
  TRAINING_CUSTOMER_DURING_MEETING: 'Utbildning av kunden under mötet',
  WHAT_TYPE_OF_INVESTMENT: 'Vilken typ av investering gäller investeringsrekommendationen?',
  TYPE_OF_INVESTMENT_OPTION_1: 'Initial investering',
  TYPE_OF_INVESTMENT_OPTION_2: 'Ytterligare investering/Omfördelning',
  TOTAL_INVESTED_CAPITAL: 'Totalt investerat kapital',
  RECOMMENDED_BUYING_AND_SELLING: 'Rekommenderat köp och försäljning av värdepapper',
  BUYING_TRANSACTIONS: 'Köptransaktioner',
  SELLING_TRANSACTIONS: 'Försäljningstransaktioner',
  PRODUCT_NAME_ISIN: 'Produktnamn & ISIN',
  ADDED_ITEMS: 'Tillagda objekt',
  CHARGE: 'Avgift',
  PLEASE_SELECT_INSTRUMENT: 'Vänligen välj instrument',
  PLEASE_ENTER_AMOUNT: 'Ange belopp',
  PLEASE_ENTER_CHARGE: 'Ange avgift',
  COMMENT_RECOMMENDED: 'Kommentar: rekommenderat köp och försäljning av värdepapper',
  COSTS_AND_FEES: 'Kostnader och avgifter',
  COMPENSATION_ADVICE_PORTFOLIO_MANAGEMENT: 'Ange ersättning för rådgivning/portföljförvaltning (inkl insättningsavgift)',
  ENTER_DATA: 'Ange data',
  BUSINESS_VOLUME: 'Affärsvolym',
  TRANSACTION_COSTS: 'Transaktionskostnader',
  FEE_PER_YEAR: 'Avgift (%) per år underliggande instrument',
  FEE_SEK_PER_YEAR: 'Avgift (SEK) per år underliggande instrument',
  FEE_PER_YEAR_COMPENSATION: 'Arvode (%) per år ersättningsrådgivning/portföljförvaltning',
  FEE_SEK_PER_YEAR_COMPENSATION: 'Arvode (SEK) per år ersättningsrådgivning/portföljförvaltning',
  TOTAL_FEE_PER_YEAR: 'Totalt arvode (%) per år ersättningsrådgivning/portföljförvaltning',
  TOTAL_FEE_SEK_PER_YEAR: 'Totalt arvode (SEK) per år ersättningsrådgivning/portföljförvaltning',
  COSTS_ASSOCIATED_ADVICE: 'Vilka kostnader är förknippade med de råd som ges?',
  DECISION: 'Beslut',
  CUSTOMERS_DECISION: 'Kundens beslut',
  ENTER_OTHER_DECISION: 'Ange annat beslut',
  COMMENTARY_ON_DECISION: 'Kommentar till beslut och eventuellt avrådande',
  CUSTOMERS_DECISION_OPTION_1: 'Följ råden ovan',
  CUSTOMERS_DECISION_OPTION_2: 'Vill du vänta, rådgivaren kommer tillbaka',
  CUSTOMERS_DECISION_OPTION_3: 'Vill vänta, kunder kommer tillbaka',
  CUSTOMERS_DECISION_OPTION_4: 'Följer inte råd som ges',
  CUSTOMERS_DECISION_OPTION_5: 'Övrigt',
  ASSIGNMENTS_AND_FEES: 'Uppdrag och arvoden',
  ENTER_FEE_IN: 'Ange avgift i %',
  TYPE_OF_FEE: 'Typ av avgift',
  TYPE_OF_FEE_OPTION_1: 'Investeringsrådgivning',
  TYPE_OF_FEE_OPTION_2: 'Portföljförvaltning',
  TYPE_OF_FEE_OPTION_3: 'Beställ på kundens initiativ',
  TYPE_OF_FEE_OPTION_4: 'Konsulttjänster',
  DEFAULT_ACCOUNT: 'Standardkonto',
  BANK: 'Bank',
  CLEARING_NUMBER: 'Clearingnummer',
  ACCOUNT_NUMBER: 'Kontonummer',
  ALLOW_ACCOUNT_NUMBERS: 'Tillåt konto- och clearingnummer som inte kan valideras',
  AGREEMENT: 'Avtal',
  AGREEMENT_CHECK_1: 'Jag har tagit del av Citroneer allmänna villkor.',
  AGREEMENT_CHECK_2: 'Jag har läst Citroneers Pre-purchase information.',
  AGREEMENT_CHECK_3: 'Jag har läst Citroneers prislista.',
  YOUR_SCHEDULED_MEETING: 'Ditt planerade möte',
  MEETING_DETAILS: 'Mötesdetaljer',
  START_RECORDING: 'Börja spela in',
  OR: 'Eller',
  YOUR_RECORDING_IS_STOPPED: 'Din inspelning stoppas',
  YOUR_RECORDING_IN_PROCESSING: 'Din inspelning håller på att bearbetas...',
  YOUR_RECORDING_IS_PAUSED: 'Din inspelning är pausad',
  ENGLISH: 'Engelska',
  RUSSIAN: 'Ryska',
  SWEDEN: 'Sverige',

};
