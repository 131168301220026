/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Container from '../../../components/Container';
import { Button, Col, Modal, Row, Table } from 'antd';
import { formatPercentage } from '../../../helpers/formatPercentage';

import MainFooter from '../../../components/MainFooter';
import { fractionalWithSpaces, twoDecimalWithSpaces } from '../../../helpers/formatFractional';
import { getPreCandidate } from '../../../data/store/portfolio/portfolioActions';
import { numberWithSpaces } from '../../../helpers/formatInteger';

import './style.scss';

export const StepTransactions = ({ pdfSource = {}, setPDFSource, portfolioId, userId, setOptStart, optAlert, setOptAlert }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();
  const history = useHistory();

  const { analyzedPortfolioDot, prospectPortfolioSet, portfolioById } = useSelector((state) => state.portfolioReducer);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  const stepsArray = sessionStorage.getItem('stepsArray')
    ? JSON.parse(sessionStorage.getItem('stepsArray'))
    : null;

  const optimalPortfolio = sessionStorage.getItem('selectedPortfolio')
    ? JSON.parse(sessionStorage.getItem('selectedPortfolio'))
    : null;

  useEffect(() => {
    if ((!optimalPortfolio || !prospectPortfolioSet) && !!stepsArray?.length) {
      setTimeout(() => {
        history.goBack();
      }, 500);
    }
  }, [stepsArray]);

  const buyData = useMemo(() => {
    
    if (analyzedPortfolioDot?.transactions?.buy) {
      const data = analyzedPortfolioDot?.transactions?.buy;
      const summ = data?.reduce((acc, item) => acc + item?.value, 0);
      return data?.map(i => {
        return { ...i, share: i.value / summ * 100 };
      });
    } return [];
  }, [analyzedPortfolioDot]);

  const sellData = useMemo(() => {
    if (analyzedPortfolioDot?.transactions?.sell) {
      const data = analyzedPortfolioDot?.transactions?.sell;
      const summ = data?.reduce((acc, item) => acc + item?.value, 0);
      return data?.map(i => {
        return { ...i, share: i.value / summ * 100 };
      });
    } return [];
  }, [analyzedPortfolioDot]);

  const columnsBuy = [
    {
      title: t('INSTRUMENT'),
      dataIndex: 'instrument',
      align: 'left',
      width: '30%',
    },
    {
      title: t('ISIN'),
      dataIndex: 'isin',
      align: 'left',
    },
    {
      title: t('VALUE'),
      dataIndex: 'value',
      align: 'right',
      render: (value) => {
        return value
          ? `${twoDecimalWithSpaces(Math.round(value * 100) / 100)} kr`
          : '';
      },
    },
    {
      title: t('SHARE_PR'),
      dataIndex: 'share',
      align: 'right',
      render: (value) => value ? formatPercentage(value) : '',
    },
    // {
    //   title: t('VALID_UNTIL'),
    //   dataIndex: 'valid',
    //   align: 'right',
    //   render: (value) => {
    //     return value === undefined ? t('UNTIL_CANCELED') : '';
    //   },
    // },
    // {
    //   title: t('PORTFOLIO'),
    //   align: 'left',
    //   dataIndex: 'portfolio_code'
    // },
    // {
    //   title: t('TICKER'),
    //   dataIndex: 'ticker',
    //   align: 'left',
    //   render: (value) => {
    //     return value !== null ? value || '- - -' : '';
    //   },
    // },
    // {
    //   title: t('SHARE_PRICE'),
    //   dataIndex: 'share_price',
    //   align: 'right',
    //   render: (value) => {
    //     return value ? `${numberWithSpaces(Math.round(value * 100) / 100)} kr` : '';
    //   },
    // },
  ];
  const columnsSell = [
    {
      title: t('INSTRUMENT'),
      dataIndex: 'instrument',
      align: 'left',
      width: '30%',
    },
    {
      title: t('ISIN'),
      dataIndex: 'isin',
      align: 'left',
    },
    {
      title: t('VALUE'),
      dataIndex: 'value',
      align: 'right',
      render: (value) => { return value ? `${numberWithSpaces(Math.round(value * 100) / 100)} kr` : ''; }
    },
    {
      title: t('SHARE_PR'),
      dataIndex: 'share',
      align: 'right',
      render: (value) => value ? formatPercentage(value) : '',
    },
    // {
    //   title: t('TICKER'),
    //   dataIndex: 'ticker',
    //   align: 'left',
    //   render: (value) => {
    //     return value !== null ? value || '- - -' : '';
    //   },
    // },
    // {
    //   title: t('UNITS'),
    //   dataIndex: 'unitsNumber',
    //   align: 'right',
    //   render: (value) => value ? Math.round(value) : ''
    // },
    // {
    //   title: t('SHARE_PRICE'),
    //   dataIndex: 'share_price',
    //   align: 'right',
    //   render: (value) => {
    //     return value ? `${numberWithSpaces(Math.round(value * 100) / 100)} kr` : '';
    //   },
    // },
  ];

  const portfolioTotal = useMemo(() => {
    const total = {
      buyTotal: 0,
      sellTotal: 0
    };
    try {
      const buyTotal = analyzedPortfolioDot?.transactions?.buy?.reduce((acc, item) => acc + item.value, 0);
      const sellTotal = analyzedPortfolioDot?.transactions?.sell?.reduce((acc, item) => acc + item.value, 0);
      total.buyTotal = buyTotal;
      total.sellTotal = sellTotal;
    } catch (e) {
      return total;
    }
    return total;
  }, [analyzedPortfolioDot]);

  const handlePrev = () => {
    if (pdfSource) {
      setPDFSource(null);
    }
    history.goBack();
  };

  useEffect(() => {
    if (optAlert?.flag) setModalCancelVisible(true);
  }, [optAlert]);

  const closeModal = () => {
    setOptAlert({ flag: false, path: '' });
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = optAlert.path;
    setOptStart(false);
    closeModal();
    history.push(path);
  };

  const handleNext = () => {
    const container7 = document
      .getElementById('pdf-part-7')
      ?.innerHTML.replaceAll('"', '\'');
    const partSeven = `<div class='opt-charts-wrapper' id='pdf-part-7'>${container7}</div>`;
    setOptStart(false);
    const parts = {
      ...pdfSource,
      page5_table_buy: partSeven,
    };
    setPDFSource(parts);
    dispatch(getPreCandidate({ portfolioId, transactionId: analyzedPortfolioDot?.transactions.id }));
    setTimeout(() => {
      history.push(
        `/clients/${userId}/optimization/${portfolioId}/suggested`
      );
    }, 500);
  };

  // const handleChangeCheckBox = (event) => {
  //   setApprove(event.target.checked);
  // };

  const handleReset = () => {
    setOptAlert({ flag: false, path: '' });
    setOptStart(false);
    history.push(`/clients/${userId}/portfolio`);
  };

  return (
    <div className="profile-wrap" style={{ paddingTop: '0' }}>
      <div className="top-btn-wrapper">
        <Button
          style={{ marginBottom: '20px' }}
          className="portfolio-details-btn portfolio-export-btn"
          onClick={handleReset}
        >
          {t('CANCEL')}
        </Button>
        <Button
          className="portfolio-details-btn optim-change-btn back-to-opt"
          onClick={handlePrev}
        >
          {t('BACK_TO_OPTIMISE')}
        </Button>
        <Button
          style={{ marginLeft: '0' }}
          disabled={!optimalPortfolio?.name}
          onClick={handleNext}
          className="btn-edit-save optimise-btn"
        >
          {t('SUGGESTED_PORTFOLIO')}
        </Button>
      </div>
      <div style={{ width: '100%' }}>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ padding: '36px 40px' }}
      >
        <Col style={{ display: 'flex', gap: '8px', alignItems: 'baseline' }}>
          <h2 style={{ margin: '0' }} className="portfolio-header-title">
            {t('TRANSACTIONS')}
          </h2>
          <span className='opt-user-name'>
          {`${portfolioById?.name}`}
          </span>
        </Col>
        {/* <Col style={{ display: 'flex', alignItems: 'baseline', gap: '9px' }}>
        <span className='holdings-text'>{t('CASH_LEVEL')}:</span>
        <span className='holdings-value'>{fractionalWithSpaces(net_cash_amount_sek)} kr</span>
        </Col> */}
        {/* <Col style={{ display: 'flex', alignItems: 'center', gap: '9px' }}>
          <CustomCheckbox
            className=''
            checked={approve}
            onChange={handleChangeCheckBox}>
            {t('APPROVE_TRANSACTIONS')}
          </CustomCheckbox>
        </Col> */}
      </Row>
      <div className="transaction-inner-wrapper" id='pdf-part-7'>
        <Container widthP={100} mb='36px' padding='30px 0 20px'>
          <Row
            justify={'space-between'}
            align={'middle'}
            style={{ marginBottom: '32px', padding: '0px 40px' }}>
            <Col>
              <h2 className="opt-paper-title">{t('BUY_TRANSACTIONS')}</h2>
            </Col>
            <Col >
              <span className='holdings-text'>{t('TOTAL')}</span>
              <span className='holdings-value'>{fractionalWithSpaces(portfolioTotal?.buyTotal)} kr</span>
            </Col>
          </Row>
          <div className="portfolio-table">
            <Table
              rowKey={() => uuidv4()}
              rowClassName={(_, index) =>
                index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
              }
              columns={columnsBuy}
              dataSource={analyzedPortfolioDot?.transactions?.buy
                ? buyData
                : []}
              size="middle"
              bordered
              pagination={false}
            />
          </div>
        </Container>
        <Container widthP={100} mb='0' padding='30px 0 20px'>
          <Row
            justify={'space-between'}
            align={'middle'}
            style={{ marginBottom: '32px', padding: '0px 40px' }}>
            <Col>
              <h2 className="opt-paper-title">{t('SELL_TRANSACTIONS')}</h2>
            </Col>
            <Col >
              <span className='holdings-text'>{t('TOTAL')}</span>
              <span className='holdings-value'>{fractionalWithSpaces(portfolioTotal?.sellTotal)} kr</span>
            </Col>
          </Row>
          <div className="portfolio-table">
            <Table
              rowKey={() => uuidv4()}
              rowClassName={(_, index) =>
                index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
              }
              columns={columnsSell}
              dataSource={analyzedPortfolioDot?.transactions?.sell
                ? sellData
                : []}
              size="middle"
              bordered
              pagination={false}
            />
          </div>
        </Container>
      </div>
      <MainFooter/>
      </div>
      <Modal
        width={650}
        className='modal-content-paper'
        open={modalCancelVisible}
        onCancel={closeModal}
        footer={null}
        zIndex={1620}
      >
      <Row justify='center'>
        <Col className='modal-title'>
          {t('WARNING_OPT')}
        </Col>
      </Row>
      <Row justify='center' className='modal-text-wrapper'>
        <Col span={18}>
          <p className='modal-text'>{t('WARNING_OPT_TEXT')}</p>
        </Col>
      </Row>

      <Row
        className='modal-button-wrapper'
      >
        <Button className='modal-action-btn cancel-btn' onClick={cancelWithoutChange}>
          {t('LEAVE_OPTIMIZATION_FLOW')}
        </Button>
        <Button className='modal-action-btn save-btn' onClick={closeModal}>
          {t('CONTINUE_OPTIMIZATION')}
        </Button>
      </Row>
    </Modal>

    </div>
  );
};

StepTransactions.propTypes = {
  pdfSource: PropTypes.obj,
  setPDFSource: PropTypes.func,
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  optAlert: PropTypes.object,
  setOptStart: PropTypes.func,
  setOptAlert: PropTypes.func,
};
